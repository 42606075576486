// Dataforsyningen
import { getDatafordelerCredentials } from './datafordeler';
// https://dawadocs.dataforsyningen.dk/dok/api
export const ADDRESS_BASE_URL = 'https://api.dataforsyningen.dk';
export const addressesUrl = (search) => `${ADDRESS_BASE_URL}/adresser?q=${search}&autocomplete=true&fuzzy=true`;
export const addressByIdUrl = (id) => `${ADDRESS_BASE_URL}/adresser/${id}?struktur=flad`;
// Datafordeler
// https://confluence.sdfi.dk/pages/viewpage.action?pageId=13664878
const BUILDING_BASE_URL = 'https://services.datafordeler.dk/BBR/BBRPublic/1/rest';
const appendCredentials = () => {
    const { username, password } = getDatafordelerCredentials();
    return `username=${username}&password=${password}`;
};
export const unitsUrl = (id) => `${BUILDING_BASE_URL}/enhed?AdresseIdentificerer=${id}&${appendCredentials()}`;
export const buildingsUrl = (id) => `${BUILDING_BASE_URL}/bygning?Id=${id}&${appendCredentials()}`;
export const installationsUrl = (buildingId) => `${BUILDING_BASE_URL}/tekniskanlaeg?Bygning=${buildingId}&${appendCredentials()}`;
export const SOLAR_INSTALLATION_CODE = '1230';
export const DAWA_OPTIONS = {
    headers: { Connection: 'keep-alive' },
};
