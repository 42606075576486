import { filterFalsy } from '@bodilenergy/util';

import { useCookie, useIsClient } from '~src/hooks';

import styles from './BookingForm.module.css';

const { bookingForm } = styles;

type BookingFormProps = {
  url: string;
};

export const BookingForm = ({ url }: BookingFormProps) => {
  const isClient = useIsClient();

  const parameters = new URLSearchParams(globalThis?.location?.search);
  const team = parameters.get('team') ?? undefined;

  const bookingUrl = useBookingUrl(url, team);

  return isClient ? <iframe className={bookingForm} title={url} src={bookingUrl} /> : null;
};

const useBookingUrl = (url: string, team?: string) => {
  const gclidQuery = useGCLIDForBookingUrl();
  const utmFieldQueries = useUTMFieldsForBookingUrl();

  const fields = filterFalsy([gclidQuery, ...utmFieldQueries, team ? `team=${team}` : undefined]).join('&');

  const urlQuery = fields ? `?${fields}` : '';

  return `${url}${urlQuery}`;
};

const useGCLIDForBookingUrl = () => {
  const { cookie: gclidCookie } = useCookie('gclid');

  return gclidCookie ? `GCLID=${gclidCookie}` : undefined;
};

const useUTMFieldsForBookingUrl = () => {
  const { cookie: utmCookie } = useCookie('utmFields');

  const utmObject = utmCookie ? JSON.parse(utmCookie) : undefined;

  if (utmObject?.expiration < Date.now()) {
    return [];
  }

  const { utmSource, utmCampaign, utmMedium } = utmObject ?? {};

  const utmSourcePart = utmSource ? `UTM_SOURCE=${utmSource}` : undefined;
  const utmCampaignPart = utmCampaign ? `UTM_CAMPAIGN=${utmCampaign}` : undefined;
  const utmMediumPart = utmMedium ? `UTM_MEDIUM=${utmMedium}` : undefined;

  return filterFalsy([utmSourcePart, utmCampaignPart, utmMediumPart]);
};
